<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12">
        <h1 id="parnert" class="display-1 text-uppercase text-center italic-text">SOMOS MIEMBROS DE:</h1>
      </v-col>
    </v-row>

    <!-- Contenedor de la banda con las imágenes en movimiento -->
    <div class="logo-marquee-container">
      <div class="logo-marquee">
        <!-- Usamos v-for para mostrar todos los logos de la lista -->
        <v-card flat class="mx-4 mt-4 marquee-item" max-width="450" v-for="(item, i) in dominiosSugeridos" :key="'logo-' + i">
          <v-card-text class="py-0">
            <v-list three-line>
              <v-list-item ripple>
                <!-- Aseguramos que las imágenes tengan un tamaño adecuado -->
                <v-img
                  :src="require(`@/assets/img/${item.logo}`)"
                  class="mr-4"
                  max-width="150"
                  min-width="100"
                  alt="Logo"
                ></v-img>
                <v-list-item-content>
                  <div class="text-uppercase">
                    <h3 class="font-weight-regular">{{ item.nombre }}</h3>
                    <span class="font-weight-regular caption">{{ item.website }}</span>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        <!-- Duplicamos las tarjetas para crear el efecto de ciclo infinito -->
        <v-card flat class="mx-4 mt-4 marquee-item" max-width="450" v-for="(item, i) in dominiosSugeridos" :key="'duplicate-logo-' + i">
          <v-card-text class="py-0">
            <v-list three-line>
              <v-list-item ripple>
                <!-- Aseguramos que las imágenes tengan un tamaño adecuado -->
                <v-img
                  :src="require(`@/assets/img/${item.logo}`)"
                  class="mr-4"
                  max-width="150"
                  min-width="100"
                  alt="Logo"
                ></v-img>
                <v-list-item-content>
                  <div class="text-uppercase">
                    <h3 class="font-weight-regular">{{ item.nombre }}</h3>
                    <span class="font-weight-regular caption">{{ item.website }}</span>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12">
        <h4 id="parnert" class="display-5 text-uppercase text-center italic-text">
          PROMOVIENDO EL DESARROLLO DEL INTERNET DE NICARAGUA DESDE 1988
        </h4>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      // Lista de miembros con sus logos y sitios web
      dominiosSugeridos: [
        { logo: 'logouni12.png', nombre: 'Universidad Nacional de Ingeniería', website: 'www.uni.edu.ni' },
        { logo: 'LOGO.png', nombre: 'LACTLD', website: 'www.lactld.org' },
        { logo: 'icann.png', nombre: 'ICANN', website: 'www.icann.org' },
        { logo: 'iana2.png', nombre: 'Internet Assigned Numbers Authority', website: 'www.iana.org' },
        { logo: 'lacnic.png', nombre: 'LACNIC', website: 'www.lacnic.net' },
        { logo: 'internet-society-isoc.png', nombre: 'Internet Society', website: 'www.internetsociety.org' }
      ]
    };
  }
};
</script>

<style scoped>
/* Estilos para el contenedor de la banda de movimiento */
.logo-marquee-container {
  overflow: hidden; /* Oculta las imágenes fuera de los límites del contenedor */
  width: 100%; /* Asegura que ocupe todo el ancho del contenedor */
  margin-top: 5px; /* Da espacio por encima de la banda */
  margin-bottom: 1px; 
}

.logo-marquee {
  display: flex;
  width: max-content; /* Permite que el contenido se expanda sin límites */
  animation: marquee 35s linear infinite; /* Controla la velocidad del movimiento */
}

@keyframes marquee {
  0% {
    transform: translateX(0); /* Empieza en la posición inicial */
  }
  100% {
    transform: translateX(-50%); /* Mueve todas las imágenes hacia la izquierda */
  }
}

/* Asegura que los v-cards no se encojan y se ajusten correctamente */
.marquee-item {
  flex-shrink: 0;
  margin-right: 20px; /* Reducir el espacio entre las tarjetas */
  max-width: 300px; /* Ancho máximo de cada tarjeta */
  width: auto;
  height: 200px; /* Ajustamos la altura para reducir el espacio en vertical */
}

/* Estilos de imagen: asegúrate de que las imágenes sean lo suficientemente grandes y no se deformen */
.v-img {
  object-fit: contain; /* Evita que las imágenes se deformen */
  width: 150px; /* Controla el ancho de la imagen */
  height: 150px; /* Ajustamos la altura para mantener la proporción */
  margin-bottom: 0px; /* Espacio debajo de la imagen */
}

/* Asegura que el contenido en la tarjeta se mantenga alineado correctamente */
.v-list-item-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}



.v-list-item-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.v-list-item-subtitle {
  font-size: 1rem;
  color: #555;
}

/* Asegura que las columnas dentro del contenedor de la banda no sean muy pequeñas */
.v-row {
  justify-content: center; /* Centra las columnas dentro del contenedor */
}
.italic-text {
  font-family: 'Times New Roman', serif; /* Fuente base estándar */
  font-size: 1rem; /* Tamaño de la fuente */
  font-style: italic; /* Estilo cursivo */
  font-weight: 400; /* Peso normal de la fuente */
  text-transform: uppercase; /* Texto en mayúsculas */
  letter-spacing: 2px; /* Espaciado entre letras */
  color: #2c3e50; /* Color oscuro para el texto */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3), 0 0 25px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1); /* Sombra suave */
}
</style>
